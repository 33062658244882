body {
    margin: 0;
}

.main-login {
    width: 100vw;
    height: 100vh;
    background: #989899;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-login{
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.left-login > h1 {
    font-size: 3vw;
    color: #77ffc0
}

.login-image{
    width: 25vw;
}

.right-login {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-login {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 35px;
    background: #e6e6ee;
    border-radius: 20px;
    box-shadow: 0px 10px 40px #00000056;
}

.card-login > h1 {
    color: #151616;
    font-weight: 800;
    margin: 0;
    text-transform: uppercase;
}

.textfield {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;
}

.textfield > input {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 15px;
    background: #464546;
    color: #ffffffde;
    font-size: 12pt;
    box-shadow: 0px 10px 40px #00000056;
    outline: none;
    box-sizing: border-box ;
}

.textfield > label {
    color: #111111de;
    margin-bottom: 10px;
}

.textfield > input::placeholder {
    color: #ffffff94;
}

.button{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;
} 

.btn-login {
    width: 100%;
    padding: 16px 0px;
    margin-top: 20px;
    border: none;
    border-radius: 8px;
    outline: none;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 4px;
    color: #2b134b;
    background: #00ff88;
    cursor: pointer;
    box-shadow: 0px 10px 40px -12px #00ff8052;
}

@media only screen and (max-width: 950px) {
    .card-login{
        width: 85%;
    }
}

@media only screen and (max-width: 600px) {
    .main-login{
        flex-direction: column;
    }
    
    .left-login > h1{
        display: none;
    }

    .left-login{
        width: 100%;
        height: auto;
    }

    .right-login{
        width: 100%;
        height: auto;
    }

    .login-image {
        width: 50vw;
    }
}